<template>
  <b-navbar
    id="base-header"
    class="base-header sticky-top w-100 align-items-center"
    :class="[
      mode === 'primary'
        ? 'primary-header justify-content-between text-dark'
        : 'secondary-header justify-content-center text-white',
      {
        'jungo-base-header': $isJungo,
      },
      {
        'bg-white':
          ($isJungo && $device.isMobile) || ($isJungo && mode === 'secondary'),
      },
    ]"
  >
    <a
      v-if="(leftArrow || leftText || leftIcon) && mode === 'secondary'"
      href="#"
      class="btn-left position-absolute h-100 d-block text-white d-flex align-items-center justify-content-center position-relative"
      :class="{'text-primary': $isJungo}"
      @click.prevent="onClickLeft"
    >
      <div v-if="hasRedDot" class="red-dot bg-accent position-absolute"></div>
      <div v-if="leftText" class="left-text text-16">{{ leftText }}</div>
      <i
        v-else-if="leftIcon"
        class="left-icon text-32"
        :class="`icon-${leftIcon}`"
      ></i>
      <i v-else class="left-icon text-32 icon-Chevron-left"></i>
    </a>
    <div v-if="hasLogo">
      <img :srcset="require(`@/assets/img/login/web-base-logo-white.png`)" />
    </div>
    <h4
      v-else
      class="base-header-title d-flex align-items-center"
      :class="[
        [
          {'font-weight-bold': isTitleBold || mode === 'primary'},
          mode === 'primary' ? 'text-20' : 'text-18',
        ],
      ]"
    >
      <template v-if="breadcrumb.length !== 0 && mode === 'primary'">
        <div
          v-for="(bread, index) in breadcrumb"
          :key="bread.router"
          class="breadcrumb-container d-flex align-items-center"
        >
          <span
            class="bread-title font-weight-bold"
            :class="{clickable: bread.enable}"
            @click="onClickBread(bread)"
            >{{ bread.name }}</span
          >
          <i v-if="index + 1 !== breadcrumb.length" class="icon-Chevron-right">
          </i>
        </div>
      </template>

      <span v-else> {{ title }} </span>
    </h4>

    <a
      v-if="(rightText || rightIcon) && mode === 'secondary'"
      href="#"
      class="btn-right text-32 position-absolute h-100 d-block text-white d-flex align-items-center justify-content-center"
      :class="{'text-secondary': $isJungo}"
      @click.prevent="onClickRight"
    >
      <div
        v-if="rightText"
        id="right-text"
        class="right-text text-16 font-weight-bold"
      >
        {{ rightText }}
      </div>
      <i v-else class="right-icon" :class="`icon-${rightIcon}`"></i>
      <div
        id="locate-point"
        class="locate-point position-absolute text-12"
        style="right: 30px; opacity: 0"
      >
        -
      </div>
      <b-popover
        triggers="click"
        target="locate-point"
        placement="bottomright"
        custom-class="hjj-popover"
        :show="hasNewScore"
        >{{ $t('你有新的成績') }}
        <i class="icon-X text-16 ml-2" @click="onClick2ClosePop"></i>
      </b-popover>
    </a>
    <div v-if="mode === 'primary'" class="d-flex align-items-center">
      <div
        v-if="!isCN && !$isJungo"
        class="notifiction-wrap d-flex align-items-center justify-content-center position-relative bg-secondary mr-4 cursor-pointer"
        @click="onClickOption('notifications')"
      >
        <i class="icon-Bell d-block text-white"></i>
        <div
          v-if="hasUnreadNotifications"
          class="notification-dot position-absolute bg-accent"
        ></div>
      </div>
      <div class="avatar-wrap">
        <b-dropdown right size="sm" variant="link" no-caret>
          <template #button-content>
            <img
              class="img-fluid w-100 rounded-circle"
              :srcset="
                require(`@/assets/img/personal/avatar/${
                  userData.avatar ? userData.avatar : '0000'
                }.png?srcset`)
              "
            />
          </template>
          <template v-for="(options, index) in primaryOption">
            <template v-for="option in options">
              <b-dropdown-item
                v-if="option.show"
                :key="option.value"
                :class="{'jungo-dropdown': $isJungo}"
                href="#"
                @click="onClickOption(option.value)"
              >
                {{ option.name }}
              </b-dropdown-item>
            </template>
            <b-dropdown-divider
              v-if="index !== primaryOption.length - 1"
              :key="index"
            ></b-dropdown-divider>
          </template>
        </b-dropdown>
      </div>
    </div>
  </b-navbar>
</template>

<script>
import LogoImg from '@/assets/img/logo.png';

export default {
  props: {
    mode: {
      type: String,
      default: 'secondary',
    },
    title: {
      type: String,
      default: '',
    },
    breadcrumb: {
      type: Array,
      default() {
        return [];
      },
    },
    isTitleBold: {
      type: Boolean,
      default: false,
    },
    leftArrow: {
      type: Boolean,
      default: false,
    },
    leftText: {
      type: String,
      default: '',
    },
    backOnClickLeft: {
      type: Boolean,
      default: true,
    },
    rightIcon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    rightText: {
      type: String,
      default: '',
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    hasRedDot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LogoImg,
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    isCN() {
      return this.$store.getters['env/isCN'];
    },
    primaryOption() {
      return [
        [
          {
            name: this.$t('個人'),
            value: 'personal',
            show: true,
          },
          {
            name: this.$t('設定'),
            value: 'setting',
            show: !this.$isJungo,
          },
          {
            name: this.$t('聯絡我們'),
            value: 'contact',
            show: !this.isCN && !this.$isJungo,
          },
        ],
        [
          {
            name: this.$t('登出'),
            value: 'logout',
            show: true,
          },
        ],
      ];
    },
    hasNewScore() {
      return (
        this.$store.getters['verification/hasNewScore'] &&
        this.$device.isMobile &&
        this.$route.name === 'verification'
      );
    },
    hasUnreadNotifications() {
      return this.$store.getters['notification/hasUnread'];
    },
  },
  methods: {
    onClickBread(bread) {
      this.$router.push({name: bread.router});
    },
    onClickLeft() {
      if (this.backOnClickLeft) {
        return this.$router.back();
      }
      return this.$emit('on-click-left');
    },
    onClickRight() {
      return this.$emit('on-click-right');
    },
    onClickOption(action) {
      switch (action) {
        case 'personal':
          return this.$router.push({name: 'personal'});
        case 'setting':
          return this.$router.push({name: 'setting'});
        case 'password':
          return this.$router.push({name: 'password'});
        case 'contact':
          this.$router.push({name: 'contact'});
          break;
        case 'logout':
          return this.logout();
        case 'notifications':
          return this.$router.push({name: 'notification'});
        default:
          break;
      }
    },
    onClick2ClosePop() {
      this.$emit('on-click-close-pop');
    },
    logout() {
      return this.$store.dispatch('user/logout').then(() => {
        this.$JPush.updateTags();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-header {
  min-height: 56px;
  background: $secondary;
  animation: delayShow 0.2s forwards;
  padding: 0;
  @media screen and (min-width: 768px) {
    animation: none;
  }
  .btn-left {
    top: 0;
    left: 0;
    text-decoration: none;
    opacity: 0;
    animation: delayShow 0.2s forwards;
    @media screen and (min-width: 768px) {
      opacity: 1;
      animation: none;
    }
    .left-icon {
      padding: 6px 6px 6px 12px;
    }
    .red-dot {
      width: 14px;
      height: 14px;
      top: 12px;
      right: 6px;
      border-radius: 50%;
      z-index: 5;
    }
    .left-text {
      padding-left: 16px;
      @media screen and (min-width: 768px) {
        background: $accent;
        padding: 5px 12px;
        margin-left: 16px;
        border-radius: $rounded-sm;
        line-height: 28px;
      }
    }
  }
  .breadcrumb-container {
    margin-right: 8px;

    .bread-title {
      font-size: 20px;
      margin-right: 8px;
      &.clickable {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    i {
      font-size: 32px;
      color: rgba(144, 147, 153, 1);
    }
  }
  .base-header-title {
    opacity: 0;
    animation: delayShow 0.2s forwards;
    @media screen and (min-width: 768px) {
      opacity: 1;
      animation: none;
    }
  }
  .btn-right {
    right: 0;
    text-decoration: none;
    .right-icon {
      padding: 6px 12px 6px 6px;
      @media screen and (min-width: 768px) {
        background: $accent;
        padding: 5px 12px;
        margin-right: 8px;
        border-radius: $rounded-sm;
        line-height: 28px;
      }
    }
    .right-text {
      padding-right: 16px;
    }
  }
  .avatar-wrap {
    width: 40px;
    height: 40px;
    /deep/ .btn {
      padding: 0;
    }
  }
  .notifiction-wrap {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    .icon-Bell {
      font-size: 30px;
    }
    .notification-dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      top: 0;
      right: 0;
    }
  }
  &.primary-header {
    background: $bgcontainer;
    padding: 0 32px;
    min-height: 80px;
    border-bottom: 1px solid $line-2;
  }

  &.jungo-base-header {
    border-bottom: 1px solid $line-2;
    color: $font-normal !important;

    @media screen and (min-width: 768px) {
      .btn-left .left-text {
        background: $secondary;
        color: $white;
      }
      .btn-right .right-icon {
        background: $primary;
        color: $white;
      }
    }
  }
}
/deep/.dropdown-menu {
  max-width: 224px;
  width: 224px;
}
/deep/.dropdown-item {
  height: 36px;
  &:hover,
  &:focus,
  &:active {
    background: $grayscale-5;
    color: $font-normal;
  }
}

.jungo-dropdown {
  /deep/ .dropdown-item {
    &:hover,
    &:focus,
    &:active {
      background: $bgsection;
    }
  }
}

@keyframes delayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
