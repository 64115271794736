<template>
  <div
    class="growth-record overflow-auto w-100 h-100 d-flex flex-column align-items-start position-relative"
  >
    <base-header
      :title="$t('成長紀錄')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
    ></base-header>
    <div
      v-if="!isLoading"
      class="straight-line position-absolute"
      :style="straightLineStyle"
    ></div>
    <div v-for="(record, index) in recordDatas" :key="`record-${index}`">
      <div class="record-wrapper d-flex">
        <div class="dot"></div>
        <div class="record-box">
          <div class="time">{{ dateFormat(record.time) }}</div>
          <div class="content">
            {{
              record.rank === 0
                ? $t('成為正式會員 , 棋力 {rank} 級', {
                    rank: ranksMapping[record.rank].label.replace('k', ''),
                  })
                : $t('通過棋力檢定 , 棋力上升至 {rank} 級', {
                    rank: ranksMapping[record.rank].label.replace('k', ''),
                  })
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import ranksMapping from '@/json/rank.json';
import day from 'dayjs';

export default {
  components: {
    BaseHeader,
  },

  data() {
    return {
      recordDatas: [],
      ranksMapping,
      day,
    };
  },
  computed: {
    records() {
      return this.$store.getters['verification/records'];
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    straightLineStyle() {
      return {
        height: `calc(100% + ${80 * (this.recordDatas.length - 3)}px)`,
      };
    },
  },
  async created() {
    await this.getRecords();
    this.$store.commit('env/setIsLoading', false);
  },
  methods: {
    getRecords() {
      this.$store.commit('env/setIsLoading', true);
      return this.$store.dispatch('verification/getRecords').finally(() => {
        this.sortedRecords();
      });
    },
    sortedRecords() {
      this.recordDatas = this.records.sort(this.sortByTime);
    },
    dateFormat(time) {
      return day(time).format('YYYY年MM月DD日');
    },
    sortByTime(a, b) {
      return b.time - a.time;
    },
  },
};
</script>

<style lang="scss" scoped>
.straight-line {
  min-height: 100%;
  border: solid 1px $line-2;
  left: 40px;
  z-index: -1;
}
.date-tag {
  padding: 0 17px;
  height: 27px;
  line-height: 27px;
  border-radius: 13px;
  opacity: 0.2;
  background-color: #000000;
}
.record-wrapper {
  margin: 32px 16px 0 31px;
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 4px #ff855e;
    background-color: #ffffff;
    margin-right: 8px;
  }
  .record-box {
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
    background-color: #ffffff;
    .time {
      color: $font-grayscale-2;
    }
  }
}
</style>
